import { Divider, Drawer } from 'antd';
import { useToggle } from 'react-use';
import classNames from 'classnames';
import { useConfigProvider } from '@/context/ConfigProvider';
import styles from './index.module.less';
import { useCallback, type ReactNode } from 'react';
import { AppSettings } from '@/shared/app-common';
import { CloseOutlined } from '@ant-design/icons';
import { useUserProvider } from '@/context/UserProvider';
// import Link from 'next/link';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';

const NavHamburger = ({ children }: { children?: ReactNode[] }) => {
  const { isMobile } = useConfigProvider();
  const { isLogin, onOpenLoginModal, onChangedLoginView, onSignOut } = useUserProvider();
  const [open, toggle] = useToggle(false);
  const router = useRouter();
  const { t } = useTranslation('common');

  const showLogin = (loginView: boolean) => {
    onOpenLoginModal(true);
    onChangedLoginView(loginView);
  };

  const bottomItems = useCallback(() => {
    return isLogin ? (
      <div className={styles.mobileNavMenuWarp}>
        <span
          className={styles.linkTitle}
          onClick={() => router.push(`${AppSettings.webHost}/dashboard`)}
        >
          {t('Dashboard')}
        </span>
        <span
          className={styles.linkTitle}
          onClick={() => router.push(`${AppSettings.webHost}/settings`)}
        >
          {t('Settings')}
        </span>
        <span className={styles.linkTitle} onClick={() => onSignOut(true)}>
          {t('LogOut')}
        </span>
      </div>
    ) : (
      <div className={styles.mobileNavMenuWarp}>
        <span className={styles.linkTitle} onClick={() => showLogin(true)}>
          {t('LogIn')}
        </span>
        <span className={styles.linkTitle} onClick={() => showLogin(false)}>
          {t('SignUp')}
        </span>
      </div>
    );
  }, [isLogin]);

  return (
    <div
      className={classNames(styles.navHamburgerWarp, {
        [styles.showClose]: open,
      })}
      onClick={toggle}
    >
      {new Array(3).fill(1).map((_, index) => (
        <span key={`burger-${index}`} className={styles.hamburgerLine} />
      ))}
      <Drawer
        style={{ background: `var(--foreground-rgb)` }}
        title={
          <>
            <Image
              src={AppSettings.logoPath}
              alt={AppSettings.name}
              style={{ height: '32px' }}
              loading="lazy"
              width={32}
              height={32}
            />
            <Image
              src={AppSettings.namePath}
              alt={AppSettings.name}
              style={{ height: '32px', marginLeft: '10px' }}
              loading="lazy"
              width={154}
              height={32}
            />
          </>
        }
        closeIcon={<CloseOutlined style={{ color: `var(--app-text-color)` }} />}
        closable
        width={isMobile ? '85%' : 400}
        placement="left"
        onClose={() => {
          toggle(true);
        }}
        // maskClosable={false}
        open={open}
        rootClassName={styles.leftMenuDrawerWarp}
        key={'left-menu-drawer'}
      >
        {/* <div className={styles.mobileNavMenuWarp}>{children}</div> */}

        {children}
        <Divider />
        {bottomItems()}

        <div className={styles.mediaSection}>
          <Link href="https://twitter.com/AnitaBestln" target="__block" className={styles.link}>
            <img src="/images/layouts/x-link-icon.webp" alt="x" />
          </Link>
          <Link
            href="https://www.facebook.com/profile.php?id=100095473568977"
            target="__block"
            className={styles.link}
          >
            <img src="/images/layouts/fb-link-icon.webp" alt="facebook" />
          </Link>
        </div>

        <div className={styles.mediaSection}>
          <Link href={`${AppSettings.webHost}/privacy-policy`} className={styles.link}>
            {t('privacyPolicy')}
          </Link>
          <Link href={`${AppSettings.webHost}/aboutus`} className={styles.link}>
            {t('termsOfUs')}
          </Link>
        </div>
      </Drawer>
    </div>
  );
};

export default NavHamburger;
