import Link from 'next/link';
import classNames from 'classnames';
import { useCallback, useMemo } from 'react';
import styles from './index.module.less';
import RetroTitle from '@/components/Common/RetroTitle';
import type { MarketplaceNavItem } from '@/components/Layouts';
import { NavItem, MobileHeader } from '@/components/Layouts';
import { AppSettings } from '@/shared/app-common';
import Image from 'next/image';
import { Button, Dropdown, MenuProps } from 'antd';
import { useUserProvider } from '@/context/UserProvider';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useConfigProvider } from '@/context/ConfigProvider';
import { ga_logEvent } from '@/shared/ga';
import { EventName } from '@/shared/event-types';
import ChangeLanguage from '@/components/Common/Language';

interface GlobalHeaderProps {
  // isShopPage?: boolean;
  // isMarketplaceHome?: boolean;
  navItems?: MarketplaceNavItem[];
  isMobile?: boolean;
  isStarData?: boolean;
}

const GlobalHeader = ({ navItems, isMobile, isStarData }: GlobalHeaderProps) => {
  const { isBlogWeb } = useConfigProvider();
  const { onOpenLoginModal, onChangedLoginView, isLogin, onSignOut } = useUserProvider();
  const { t } = useTranslation('common');
  const router = useRouter();

  const UnloginHeader = useMemo(() => {
    return (
      <div
        className={styles.unLogin}
        style={{
          display:
            !isLogin && !router.pathname.includes('instagram-tracking-blog') ? 'block' : 'none',
        }}
      >
        <span
          onClick={() => {
            onOpenLoginModal(true);
            // router.push('/dashboard')
          }}
        >
          {t('Join now')}
        </span>
        <span>{t('to learn more about who you care about')}</span>
      </div>
    );
  }, [!isLogin && !router.pathname.includes('instagram-tracking-blog')]);

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Link
          href={'/settings'}
          onClick={() => {
            ga_logEvent(EventName.header_Settings);
          }}
        >
          {t('Settings')}
        </Link>
      ),
    },
    {
      key: '2',
      label: (
        <Link
          href={'/billing-records'}
          onClick={() => {
            ga_logEvent(EventName.header_Payment_History);
          }}
        >
          {t('PaymentHistory')}
        </Link>
      ),
    },
    {
      key: '3',
      label: (
        <Link
          href={'/'}
          onClick={() => {
            onSignOut();
            ga_logEvent(EventName.header_Logout);
          }}
        >
          {t('LogOut')}
        </Link>
      ),
    },
  ];

  const getNavLeftSection = useMemo(() => {
    // if (!!isMarketplaceHome) {
    return (
      <div className={styles.navTitleContainer}>
        <div className={styles.catchPhrase}>
          <Link href="/">
            <RetroTitle
              text={
                <div style={{ height: '32px' }}>
                  <Image
                    className={styles.logo}
                    src={AppSettings.logoPath}
                    alt={AppSettings.name}
                    loading="lazy"
                    width={32}
                    height={32}
                  />
                  <img
                    src={
                      router.pathname.includes('starData')
                        ? '/images/layouts/dolphin_radar.webp'
                        : AppSettings.namePath
                    }
                    alt={AppSettings.name}
                    style={{ height: '32px', marginLeft: '10px' }}
                  />
                  {!isStarData ? (
                    <Image
                      src={'/images/layouts/collect-icon.webp'}
                      alt="collect-icon.webp"
                      width={32}
                      height={32}
                    />
                  ) : (
                    ''
                  )}
                </div>
              }
              // textColor="#ff401a"
              fontSize={28}
              showAlert={true}
            />
          </Link>
        </div>
      </div>
    );
  }, []);

  const formatMenuData = useCallback(
    (navItems?: MarketplaceNavItem[]): MarketplaceNavItem[] => {
      if (isMobile) {
        return navItems?.reduce((acc: MarketplaceNavItem[], cur: MarketplaceNavItem) => {
          const tempItems = cur?.dropdownItems
            ? [...acc, { ...cur, dropdownItems: null }, ...cur?.dropdownItems]
            : [...acc, cur];
          return tempItems;
        }, []);

        // const uniqueGaNames = new Set<any>();
        // const uniqueData = tempList.filter((item) => {
        //   if (!uniqueGaNames.has(item?.gaName)) {
        //     uniqueGaNames.add(item?.gaName);
        //     return true;
        //   }
        //   return false;
        // });

        // return uniqueData;
      }
      return navItems;
    },
    [isMobile],
  );

  const renderNavRightSection = useMemo(() => {
    return formatMenuData(navItems)?.map((item, index) => (
      <NavItem
        item={item}
        key={index}
        className={classNames('', {
          'mobile-left-menu-item': isMobile,
        })}
      />
    ));
  }, [formatMenuData, isMobile, navItems]);

  if (isMobile) {
    return <MobileHeader>{renderNavRightSection}</MobileHeader>;
  }

  return (
    <div className={styles.headerContainer} style={{ position: isStarData ? 'absolute' : 'fixed' }}>
      {UnloginHeader}
      <div
        style={{ background: isStarData ? 'transparent' : 'var(--foreground-rgb)' }}
        className={classNames(styles.globalHeaderWarp)}
      >
        <div className={styles.navSection}>{getNavLeftSection}</div>
        <div className={styles.navSection} style={{ justifyContent: 'center' }}>
          {renderNavRightSection}
        </div>

        <div className={styles.navSection}>
          {isLogin
            ? !isBlogWeb && (
              <Dropdown menu={{ items }} placement="bottom" autoAdjustOverflow={false}>
                <Button
                  className={styles.login}
                  onClick={() => {
                    router.push('/dashboard');
                    ga_logEvent(EventName.header_Dashboard);
                  }}
                >
                  {t('Dashboard')}
                </Button>
              </Dropdown>
            )
            : !isBlogWeb && (
              <Button
                className={styles.login}
                onClick={() => {
                  onOpenLoginModal(true);
                  onChangedLoginView(true);
                  ga_logEvent(EventName.header_SignIn);
                }}
              >
                {t('SignIn_SignUp')}
              </Button>
            )}
          <ChangeLanguage />
        </div>
      </div>
    </div>
  );
};

export default GlobalHeader;
